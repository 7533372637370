import React, { useEffect, useState, useRef } from "react";
import styled from "styled-components";
import { navigate } from "gatsby-link";
import gsap from "gsap";
import loadable from '@loadable/component'

import useMedia from "@hooks/useMedia";

import colors from "@styles/colors";
import media from "@styles/media";
import text from "@styles/text";

import { StaticImage } from "gatsby-plugin-image";

const PrimaryButton = loadable(() => import("@components/PrimaryButton"));
const TextAnimation = loadable(() => import("@components/TextAnimation"));

type props = {
  loading: boolean;
};

const Challenging: React.FC<props> = ({ loading }) => {

  const [titleTrigger, setTitleTrigger] = useState(false);
  const [textTrigger, setTextTrigger] = useState(false);

  const wrapperRef = useRef(null);
  const image1Ref = useRef(null);
  const image2Ref = useRef(null);

  const tlStart = useMedia("top-=200 top", "top-=200 top", "top-=110% top", "");
  const imgZoomStart = useMedia("top-=200 top", "top-=200 top", "top-=80% top");

  useEffect(() => {
      const tl = gsap.timeline({
        scrollTrigger: {
          trigger: wrapperRef.current,
          start: tlStart,
        },
      });
      tl.to(
        image1Ref.current,
        {
          duration: 0.8,
          height: "100%",
          ease: "circ.inOut",
        },
        0
      );
      tl.to(
        image2Ref.current,
        {
          duration: 1,
          height: "100%",
          ease: "circ.inOut",
        },
        0.5
      );
      tl.call(setTitleTrigger, [true], 0.5);
      tl.call(setTextTrigger, [true], 0.7);

      const imgZoom = gsap.timeline({
        scrollTrigger: {
          trigger: wrapperRef.current,
          start: imgZoomStart,
          scrub: true,
        },
      });
      imgZoom.to(
        image1Ref.current,
        {
          scale: "1.2",
        },
        0
      );
      imgZoom.to(
        image2Ref.current,
        {
          scale: "1.2",
        },
        0
      );

      return () => {
        tl.kill();
        imgZoom.kill();
      };
    
  }, []);

  return (
    <Wrapper ref={wrapperRef}>
      <Title>
        <TextAnimation
          textArray={[() => "Challenging", () => "the status quo"]}
          className="challenging-title"
          trigger={titleTrigger}
          height={useMedia("5.556vw", "5.556vw", "5.755vw", "12.800vw")}
        />
      </Title>
      <Text>
        <TextAnimation
          textArray={[
            () =>
              "We are scientists, engineers, and visionaries with innovation and environmentalism at the heart of our mission. Our team is dedicated to the continued development of groundbreaking solutions to transform operations in the oil and gas industry for the better. ",
          ]}
          className="challenging-text"
          trigger={textTrigger}
          height={useMedia("10.417vw", "10.417vw", "15.827vw", "32vw")}
        />
      </Text>
      <PrimaryButton
        backgroundColor={colors.keppel100}
        textColor={colors.black}
        text="About our Team"
        hoverColor={colors.keppel60}
        width={useMedia("fit-content", "fit-content", "fit-content", "100%")}
        onClick={() => navigate('/about')}
      />
      <Image1Wrapper>
        <Image1 ref={image1Ref}>
          <StaticImage
            src={"../../images/jpg/challenge1.jpg"} 
            alt="office space 1"
            placeholder="blurred"
            style={{width: '100%', height: '100%'}}
          />
        </Image1>
      </Image1Wrapper>
      <Image2Wrapper>
        <Image2 ref={image2Ref}>
          <StaticImage
            src={"../../images/jpg/challenge2.jpg"} 
            alt="office space 2"
            placeholder="blurred"
            style={{width: '100%', height: '100%'}}
          />
        </Image2>
      </Image2Wrapper>
    </Wrapper>
  );
};

export default Challenging;

const Wrapper = styled.section`
  width: 100%;
  background-color: ${colors.black};
  position: relative;
  box-sizing: border-box;

  ${media.fullWidth} {
    padding-top: 13.264vw;
    padding-left: 11.319vw;
    padding-bottom: 51.111vw;
  }

  ${media.desktop} {
    padding-top: 13.264vw;
    padding-left: 11.319vw;
    padding-bottom: 51.111vw;
  }

  ${media.tablet} {
    padding-top: 17.266vw;
    padding-left: 5.995vw;
    padding-bottom: 39.688vw;
  }

  ${media.mobile} {
    padding-top: 164.8vw;
    padding-left: 6.667vw;
    padding-right: 6.667vw;
    padding-bottom: 21.333vw;
  }
`;

const Title = styled.h2`
  color: ${colors.culturedWhite100};
  display: flex;
  flex-direction: column;

  ${media.fullWidth} {
    ${text.desktopXLHeading}
    width: 34.722vw;
    margin-bottom: 2.778vw;
  }

  ${media.desktop} {
    ${text.desktopXLHeading}
    width: 34.722vw;
    margin-bottom: 2.778vw;
  }

  ${media.tablet} {
    ${text.tabletSHeading}
    width: 38.010vw;
    margin-bottom: 2.398vw;
  }

  ${media.mobile} {
    ${text.mobileSHeading}
    width: 84.533vw;
    margin-bottom: 5.333vw;
  }
`;

const Text = styled.p`
  color: ${colors.culturedWhite100};
  display: flex;

  ${media.fullWidth} {
    ${text.desktopBodyCopy1}
    width: 24.306vw;
    margin-bottom: 2.778vw;
  }

  ${media.desktop} {
    ${text.desktopBodyCopy1}
    width: 24.306vw;
    margin-bottom: 2.778vw;
  }

  ${media.tablet} {
    ${text.tabletBodyCopy1}
    width: 44.365vw;
    margin-bottom: 4.796vw;
  }

  ${media.mobile} {
    ${text.mobileSmallBody}
    width: 84.533vw;
    margin-bottom: 8vw;
  }
`;

const Image1Wrapper = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;

  ${media.desktop} {
    width: 38.264vw;
    height: 52.5vw;
    top: 8.611vw;
    right: 3.472vw;
  }

  ${media.fullWidth} {
    width: 38.264vw;
    height: 52.5vw;
    top: 8.611vw;
    right: 3.472vw;
  }

  ${media.tablet} {
    width: 39.089vw;
    height: 52.878vw;
    top: 13.309vw;
    right: 5.995vw;
  }

  ${media.mobile} {
    width: 70.667vw;
    height: 95.467vw;
    top: 16vw;
    right: 0vw;
  }
`;

const Image1 = styled.div`
  width: 100%;
  height: 0%;
  object-fit: cover;
`;

const Image2Wrapper = styled.div`
  position: absolute;
  z-index: 2;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;

  ${media.fullWidth} {
    width: 33.889vw;
    height: 35.972vw;
    right: 27.083vw;
    top: 41.944vw;
  }

  ${media.desktop} {
    width: 33.889vw;
    height: 35.972vw;
    right: 27.083vw;
    top: 41.944vw;
  }

  ${media.tablet} {
    width: 34.892vw;
    height: 37.05vw;
    right: 21.823vw;
    top: 54.556vw;
  }

  ${media.mobile} {
    width: 61.867vw;
    height: 65.6vw;
    right: unset;
    left: 0vw;
    top: 81.867vw;
  }
`;

const Image2 = styled.div`
  width: 100%;
  height: 0%;
  object-fit: cover;
`;
